<template>
  <div>
    <el-input placeholder="Кадастровый номер" v-model="value"></el-input>
  </div>
</template>

<script>

import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RosreestrAPI} from '@/core/infrastructure/api/modules/RosreestrAPI'

export default {
  name: 'enterCoordinates',
  components: { CoordinateSystem },
  props: {
  },
  data () {
    return {
      value: null,
      cs: 3857,
      coordinates: {
        x: 0,
        y: 0
      }
    }
  },
  methods: {
    changeCS (value) {
      this.$emit('change-cs', value)
    },
    async findByCadastralNumber() {
      this.$emit('loading-show')
      let success = false
      const totalTrying = 10
      let objectInfo = null
      //find area
      for (let currentTrying = 0; currentTrying < totalTrying && !success; currentTrying++) {
        try {
          objectInfo = await APIClient.shared.request(new RosreestrAPI.FindAreaInfoByCadastralNumber(this.value))
        } catch (e) { }
        if (objectInfo !== null) {
          success = true
        }
      }

      if (objectInfo === null || objectInfo?.total === 0) {
        //find building
        success = false
        for (let currentTrying = 0; currentTrying < totalTrying && !success; currentTrying++) {
          try {
            objectInfo = await APIClient.shared.request(new RosreestrAPI.FindBuildingInfoByCadastralNumber(this.value))
          } catch (e) { }
          if (objectInfo !== null) {
            success = true
          }
        }
      }

      if (objectInfo && objectInfo.features.length > 0) {
        const feature = objectInfo.features[0]
        this.coordinates.x = feature.center.x
        this.coordinates.y = feature.center.y
        this.changeCS(this.cs)
        this.$emit('change-coordinates', this.coordinates)
      } else {
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: 'Кадастровый номер не найден'
        })
      }
      this.$emit('loading-hide')
    }
  }
}
</script>

<style scoped>

</style>
